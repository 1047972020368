@import '../../../Assets/Common/base.scss';

.formpopup-container {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: $white;
    z-index: 999;
    width: 90%;
    max-width: 371px;
    margin: auto;
    overflow: auto;

    .form-header-wrap {
        padding: 20px;
        border-bottom: 1px solid rgba($base, 0.2);
        display: flex;
        justify-content: space-between;

        .title-wrap {
            .title-text {
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
            }
        }
    }

    .formpop-body {
        padding: 24px 24px 24px 24px;

        .body-top-text {
            color: rgba($base, 0.6);
            line-height: 24px;
        }

        .form-wrapper {
            margin-top: 20px;

            .formwrap {
                padding-bottom: 16px;
                // .group{
                //     .form-input{
                //         margin: 0;
                //     }
                // }
                .input_error{
                    font-size: 14px;
                    color: #e71111;
                }
            }

            .label-form {
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                color: #000;

            }

            .form-input {
                width: 100%;
                padding: 10px 20px;
                height: 36px;
                border: 1px solid rgba($base, 0.2);
                box-sizing: border-box;
                border-radius: 4px;
                margin-top: 8px;

                &:focus {
                    border: 1px solid rgba($base, 0.8);
                }
            }

            textarea {
                width: 100%;
                padding: 10px 20px;
                border: 1px solid rgba($base, 0.2);
                box-sizing: border-box;
                border-radius: 4px;
                margin-top: 8px;

                &:focus {
                    border: 1px solid rgba($base, 0.8);
                }
            }

            .form-btn-wrap {
                margin-top: 16px;
            }
        }

        .socail-icon {
            display: flex;
            align-items: center;
            justify-content:center;
            gap: 14px;
            flex-wrap: wrap;
            margin-top: 40px;
            .icon {
                cursor: pointer;
            }
        }
    }

    .icon-cross {
        display: block;
        width: 10px;
        height: 10px;
        background-color: $base;
        -webkit-mask: url("../../../Assets/icons/cross.svg") no-repeat center;
        mask-image: url("../../../Assets/icons/cross.svg") no-repeat center;
        cursor: pointer;
    }
}