@import '../../Assets/Common/base.scss';

.home-wrapper {
    padding-bottom: 120px;
    overflow: hidden;
    .hero-section {
        .hero-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // gap: 40px;
            align-items: flex-end;
            padding-top: 40px;

            @media(max-width:992px) {
                grid-template-columns: 1fr;
                padding-top:32px;
            }

            .hero-leftSidebar {
                .bneed-p {
                    font-weight: 600;
                    font-size: 12px;
                    letter-spacing: 0.36em
                }
                .heading-wrap {

                }
                .subheading-wrap {
                    max-width: 380px;
                    margin-top: 16px;
                }

                .button-wrap {
                    margin-top: 36px;
                    @media(max-width:768px) {
                        margin-top: 24px;
                    }
                }
            }
            .hero-rightSidebar {
                align-self: flex-start;
                .image-wrap {
                    position: absolute;
                    top: -10%;
                    right: -5%;
                     img {
                        max-width: 620px;
                     }
                     @media (max-width: 768px) {
                      display: none;
                    }
                }
            }
        }
    }

    // client section

    .clientLogo-section {
        .clientSection-container {
            background: rgba($lgray, 0.3);
       
            padding:  36px 0;
            // @media(max-width:768px) {
            //    width: 90%;
            //    margin: auto;
            // }
            .client-div {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 72px;
                flex-wrap: wrap;
                row-gap: 40px;
                @media (max-width: 768px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 16px;
                }
                @media (max-width: 475px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    row-gap: 40px;
                }
            .client-img-wrap { 
                 @media (max-width: 768px) {
                background-color: #FFF;
                border-radius: 8px;
                height: 110px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
            }
                img {
                    max-width: 100%;
                }
            }
        }
    }
    } 
}