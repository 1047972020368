.terms-custom, .privacy-policy-custom, .cookie-policy-custom{
    padding: 72px 0;

    .sub-heading{
        margin-bottom: 24px;
    }
    p, li{
        font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 16px;
    }
}
