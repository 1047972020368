@import './buttons';
@import './container';
@import './theme';
@import './theme';
@import './setup';
@import './common';
@import '../Scss/flexgrid';
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  scroll-behavior: smooth;
}
* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
  }
  
  html,
  body,
  div,
  span,
  applet,
  object,
  // iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  // embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  video {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }
  
  html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
  }
  
  :before,
  :after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    transition: all 0.25s ease;
  }
  
  html {
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }
  
  i {
    font-style: normal;
  }

  body{
    box-sizing: border-box;
    font-family: 'Syne', sans-serif;
    overflow-x: hidden;
 }
 a{
  text-decoration: none;
 }
  
  
  ul {
    margin: 0;
    padding: 0;
  
    li {
      list-style: none;
    }
  }

  ::placeholder,
textarea::placeholder {
  text-transform: none !important;
}

:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  text-transform: none !important;
}

::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {

  text-transform: none !important;
}

//used to remove scroll of body when popup open
.avoidscroll {
  overflow: hidden;
  height: 100vh;
  overflow-y: hidden;
}