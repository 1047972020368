@import '../../../Assets/Common/base.scss';

// .contact-container {
//     .office-address-wrapper {
//         display: flex;
//     }
// }
.contact-container{
    width: 100%;
    .office-address-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap:100px;
        justify-content: space-between;
        margin-top: 72px;
        @media(max-width:768px) {
          gap: 60px;
         }
        @media(max-width:600px) {
           display: grid;
           grid-template-columns: 1fr;
        }
        @media(max-width:375px) {
            display: grid;
            grid-template-columns:  1fr;
         }
        .address-wrap {
            .office-location {
                font-size: 36px;
                font-weight: 600;
                line-height: 36px;
                margin-bottom: 8px;
            }
            .phone-text {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                font-family: 'Poppins', sans-serif;

            }
            .address {
                line-height: 24px;
                font-weight: 400;
                margin-top: 8px;
                font-family: 'Poppins', sans-serif;
            }
        }
    }

    .contactus-mapSection {
        margin-top: 160px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 120px;
        align-items: flex-end;
        @media(max-width:768px) {
            gap: 40px;
            margin-top: 60px;
            grid-template-columns: 1fr;
        }
        .map-wrap {
            // width: 475px;
            // height: 475px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .form-wrap {
            .getIntouch {
                font-size: 32px;
                line-height: 88px;
            }
            .form-container{
                .group{
                    .form-input{
                        margin: 0;
                    }
                }
                .input_error{
                    font-size: 14px;
                    color: #e71111;
                }
            }
        }
     
    }
}