/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

.App {
  text-align: center;
}
.coming-soon-wrap{
  background-image:
  linear-gradient(180deg, rgba(32, 32, 32, 0) 44.27%, rgba(0, 0, 0, 0.6) 65.36%), url(
'bg.jpg');
  background-color: #000;
  height: 100vh; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  padding: 0 64px;
  font-family: 'Montserrat', sans-serif;
}
.cs-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;
}
.cs-logo{
  width: 123px;
height: 48px;
}
.cs-link{
  display: flex;
  align-items: center;
  gap: 8px;
text-decoration: none;
}
.cs-link-number{

  font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #FFFFFF;
}
.cs-link img{
  width: 16px;
  height: 16px;
}
.cs-section{
  height: calc(100vh - 180px); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cs-section h1{
  font-weight: 500;
  font-size: 64px;
  line-height: 78px;
  /* identical to box height */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.48em;
  
margin: 0 0 56px 0;
  color: #FFFFFF;
}

.cs-section h6{
  font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #FFFFFF;
margin: 0;
}
.form-group{
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 36px;
}
.form-control{
  background: #FFFFFF;
border-radius: 18px;
width: 220px;
padding: 8px 24px;
border: none;
font-weight: 400;
font-size: 14px;
line-height: 17px;
/* identical to box height */
outline: none;

color: rgba(32, 32, 32, 0.4);
}
.submitBtn{
  background: #EF1C79;
border-radius: 24px;
font-weight: 400;
font-size: 16px;
line-height: 20px;
text-transform: uppercase;
color: #FFFFFF;
padding: 8px 32px;
border: none;
outline: none;
cursor: pointer;
}

.cs-timer{
  padding: 56px 0;
}
.cs-timer .time span{
  width: 48px;
height: 64px;
background: #3C86F2;
border-radius: 8px;
}

.countdown-wrapper{
  display: flex;
  align-items: center;
}
.time-section .time{
  width: 96px;
  height: 96px;
  background: #3C86F2;
border-radius: 8px;
font-weight: 400;
font-size: 48px;
line-height: 59px;
display: flex;
align-items: center;
justify-content: center;
color: #FFFFFF;
}

.timeDot{
  padding: 0 8px;
}
.time-text{
  font-weight: 400;
font-size: 12px;
line-height: 15px;
/* identical to box height */

display: flex;
align-items: center;

color: #FFFFFF;
text-transform: uppercase;
}
.time-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .coming-soon-wrap{
  padding: 0 16px;
}
.cs-link-number{
  display: none;
}
.cs-section h1{
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.24em;
margin: 0 0 32px 0;
}
.cs-section h6{
text-align: center;
}
}