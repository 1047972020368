@import '../../../Assets/Common/base.scss';

.PagaNotFound-main-container {
    background-color: transparent;
    margin: 74px 0px 93px 0px;

    .PagaNotFound-sub-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;

        .sub-img-container {

            img {
                max-width: 100%;
                height: 100%;
            }
        }

        .sub-content-container {
            margin-top: 24px;
            text-align: center;

            .sub-content-heading {
                font-family: 'Syne', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                line-height: 58px;
                background: linear-gradient(90deg, #0184FF -22.62%, #B295F1 124.29%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

            .sub-content-description {
                font-family: 'Syne', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: rgba(32, 32, 32, 0.6);
                margin: auto;
                margin-top: 13px;
                width: 434px;

                @media screen and (max-width: 600px) {
                    width: 100%;
                }
            }

            .bottom-button {
                margin-top: 48px;
                background: linear-gradient(90deg, #0184FF -22.62%, #B295F1 124.29%);
                box-shadow: 0px 0px 20px rgba(90, 0, 255, 0.2);
                color: $white;
                border-radius: 24px;
                border: none;
                padding: 12px 60px;
                font-family: 'Syne';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                cursor: pointer;
            }
        }
    }
}