
.container {
    max-width: 1366px;
    width: 90%;
    margin: auto;
}

.container-fluid {
    max-width: 1366px;
    width: 100%;
    margin: auto;
}

.container-left {
    width: 91%;
    max-width: 1366px;
    margin-right: auto;
    @media (max-width:1200px) {
        margin: auto;
    }
}

.container-right {
    width: 95%;
    max-width: 1366px;
    margin-left: auto;

    @media (max-width:1200px) {
        margin: auto;
    }
}

