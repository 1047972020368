footer {
    background-color: #202020;

    .footer-wrapper {
        padding: 64px 0 34px 0;
    }

    .footerHero {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        gap: 24px;
        align-items: flex-start;
        padding-bottom: 34px;
        @media (max-width:768px) {
            grid-template-columns: 1fr;

        }
    }

    .logo-wrapper {
        cursor: pointer;
        margin-bottom: 24px;

        .logo {
            text-align: center;
            align-items: center;
            width: 155px;
        }
    }



    .hero-text{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFF;
        width: 90%;

        @media (max-width:768px) {
            width: 100%;
        }
    }

    .HeroItem {
        h6 {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #FFF;

            @media (max-width:1024px) {
                margin-top: 24px;
            }
        }

        .heroItemList {
            .listItem {
                padding: 8px 0;
            }

            a {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #FFF;
                text-decoration: none;

                &:hover {
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }
    }

    .social-handle-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 16px;

        .social-handle-item {
            cursor: pointer;
            img {
                width: 20px;
                height: 20px;
                object-fit: contain;
            }
        }
    }

    .footer-highlights {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .highlights-menu {
            display: flex;
            align-items: center;
            gap: 24px;

            a {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #FFFFFF;
                text-decoration: none;
            }
        }

        @media (max-width:768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #FFF;

            a {
                text-decoration: underline;
                color: inherit;
            }
        }
    }

    .divider {
        border-top: 1px solid #FFF;
        height: 0;
        overflow: hidden;
        margin: 24px 0;
    }

    .conatc-us-heading {
        margin-top: 16px;
    }

    .icon-call {
        display: block;
        width: 15px;
        height: 15px;
        background-color: #fff;
        -webkit-mask: url("../../Assets/icons/call.svg") no-repeat center;
        mask-image: url("../../Assets/icons/call.svg") no-repeat center;
      }

      .icon-mail {
        display: block;
        width: 15px;
        height: 15px;
        background-color: #fff;
        -webkit-mask: url("../../Assets/icons/mail.svg") no-repeat center;
        mask-image: url("../../Assets/icons/mail.svg") no-repeat center;
      }
      
    .icon-loaction {
        display: block;
        width: 15px;
        height: 15px;
        background-color: #fff;
        -webkit-mask: url("../../Assets/icons/location.svg") no-repeat center;
        mask-image: url("../../Assets/icons/location.svg") no-repeat center;
      }
      
      .address-wrap-footer {
        margin-top: 10px;

        .icon-wrap-footer {
            display: flex;
            align-items:flex-start;
            margin-top: 12px;
            gap: 7px;

            &.margin-topp {
                margin-top: 22px;
            }
            p {
                font-weight: 400;
          font-size: 16px;
           line-height: 24px;
          color: #FFF;
           text-decoration: none;
           margin-top: -5px;
            }
        }
      }

}