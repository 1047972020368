@import '../../../../Assets/Common/base.scss';

@mixin shrinkLabel {
  top: -14px;
  font-size: 16px;
  color: $base;
}

.group {
  position: relative;
  margin-top: 36px ;

  .form-input {
    background: none;
    background-color: white;
    font-size: 12px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $gray;
    // margin: 25px 0;
    color: $base;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    // &:focus ~ .form-input-label {
    //   @include shrinkLabel();
    // }
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  .form-input-label {
    color: #808080;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;

    // &.shrink {
    //   @include shrinkLabel();
    // }
  }
}

::placeholder,
textarea::placeholder {
  color: #808080;
  font-size: 16px;
  font-weight: normal;
  // position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}

:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #808080;
  font-size: 16px;
  font-weight: normal;
  // position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}

::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #808080;
  font-size: 16px;
  font-weight: normal;
  // position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}