@import '../../../Assets/Common/base.scss';

.career-container {
    .career-top-text-wrap {
        padding-top:24px;
        width: 75%;
        margin: auto;

        @media(max-width:768px) {
            width: 100%;
        }
       .career-top-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.6em;
        color: rgba($base, 0.6);
        text-align: center;
       }
    }

    .career-top-btn-wrap {
        margin-top: 72px;
        button{
            font-weight: 400;
font-size: 18px;
line-height: 24px;
        text-transform: capitalize;
        }
        @media(max-width:768px) {
            margin-top: 30px;
        }
    }

    .openPosition-container {
        margin-top: 120px;

        @media(max-width:768px) {
            margin-top: 60px;
        }
        .openPostion-text {
            font-size: 32px;
            font-weight: 600;
            line-height: 38px;
        }

        .button-switch-wrap {
            margin: 36px 0;
            display: flex;
            gap: 36px;
            row-gap: 24px;
            flex-wrap: wrap;

            .switch-wrap-btn {
                background-color: transparent;
                padding: 8px 20px;
                height: 40px;
                cursor: pointer;
            }
            .btn-inactive {
                border: none;
                outline: none;
                text-align: left;
                padding-left: 0;
                font-size: 14px;
                color: $base;
                text-transform:capitalize;
            }
        }

        .openPosition-warp {
            border-top: 1px solid  #EEEEEE;
            padding-top: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media(max-width:600px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
            
            .openPosition-title {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
            }
            .openPosition-details {
                font-weight: 400;
                color: rgba($base, 0.6);
            }
            .jobType-wrap {
                margin-top: 18px;
                margin-bottom: 36px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 16px;
                .jobtype-btn {
                    display: inline-flex;
                    align-items: center;
                    gap: 6px;
                    background-color: transparent;
                    outline: none;
                    padding: 8px 14px;
                    border-radius: 24px;
                    border: 1px solid rgba($base, 0.1);
                }
            } 
        }
    }

    .pagination-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
    }
    .icon-loaction {
        display: block;
        width: 15px;
        height: 15px;
        background-color: $base;
        -webkit-mask: url("../../../Assets/icons/location.svg") no-repeat center;
        mask-image: url("../../../Assets/icons/location.svg") no-repeat center;
      }

      .icon-watch {
        display: block;
        width: 15px;
        height: 15px;
        background-color: $base;
        -webkit-mask: url("../../../Assets/icons/clock.svg") no-repeat center;
        mask-image: url("../../../Assets/icons/clock.svg") no-repeat center;
      }
}