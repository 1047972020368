@import '../../../Assets/Common/base.scss';

.form-container {
    .btn-warp {
        margin-top: 36px;
    }

    .input_error{
        font-size: 14px;
        color: #e71111;
    }
}