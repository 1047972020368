@import '../../Assets/Common/base.scss';


header{
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
    background: #FFF;
    // background: linear-gradient(180deg, #FFFFFF 77.69%, rgba(255, 255, 255, 0.19) 101.79%);
    transition:.6s ease;

 &.active {
  background: #FFF;
  box-shadow: 0px 4px 16px rgba(244, 244, 244, 0.4);
}
    nav{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      width: 100%;
      padding: 8px 0;
    
      .hamburger-toggle {
    
        display: none;
    
        @media (max-width:992px) {
          display: block;
          width: 30px;
          height: 30px;
          text-align: center;
          padding: 5px;
          border-color: transparent;
          background: white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;    
        }
    
        .hamburger-toggle-item {
          height: 1px;
          width: 100%;
          color: #222;
          font-size: 25px;
          border: 1px solid black;
          margin: 3px;
        }
      }
    
    
      .logo-wrapper {
        cursor: pointer;
        .logo {
          text-align: center;
          align-items: center;
          width: 115px;
        }
      }
    
    
    
    
    
      .nav-menu-wrapper {
        display: flex;
        flex-basis: auto;
        align-items: center;
        @media (max-width:992px) {
          display: block;
          flex-basis: 100%;
          flex-grow: 1;
          align-items: center;
          padding: 2rem 0;
        }
        .menu-list-wrapper{
          display: flex;
          flex-direction: row;
          padding-left: 0;
          margin-bottom: 0;
          list-style: none;
          align-items: center; 
          @media (max-width:992px) {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            gap:24px;
            align-items: flex-start;
          }
          .menu-item{
            list-style-type: none;
            
            cursor: pointer;
            padding: 8px 16px;
            .menu-item-anchor{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #202020;
            text-decoration: none;
            transition-duration: 800ms;
            &:hover{
                background: linear-gradient(90deg, #B295F1 -32.14%, rgba(1, 132, 255, 0.4) 140.48%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        
        }
                .active{
          background: linear-gradient(90deg, #B295F1 -32.14%, rgba(1, 132, 255, 0.4) 140.48%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
      }

          }
        }
        @media (max-width:992px) {
          &:not(.show) {
            display: none;
        }
          }
     
      }
    
     
      } 
}


 