@import '../../../Assets/Common/base.scss';

.ourBusiness-container {
   display: grid;
   grid-template-columns: 1fr auto 1fr;
   align-items: center;
   column-gap: 36px;
   @media(max-width:768px) {
    grid-template-columns: 1fr ;
}
   .busineess-leftSidebar {
    @media(max-width:768px) {
       order: 5;
    }
    .number-conunt {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 16px;
        @media(max-width:768px) {
           flex-direction: column;
           align-items: flex-start;
         }
        .image-wrap {
            width:fit-content;
            img {
                max-width: 100%;
            }
        }
        .count-text {
            .heading-count {
                font-size: 64px;
                font-weight: 500;
                line-height: 52px;
                color: $base;
                @media(max-width:768px) {
                   font-size: 40px;
                }
            }
        }
    }
    .content-wrap {
        margin-top: 36px;
        .content-text {
            font-size: 20px;
            line-height: 32px;
            font-weight: 500;

        }
        .content-btn-wrap {
            margin-top: 24px;
            a{
                text-decoration: none;
                &:hover{}
            }
        }
    }
   }
   .v-line {
    width: 1px;
    height: 100%;
    background: linear-gradient(180deg, $secondary -12.34%, $primary 131.12%);
    @media(max-width:768px) {
        display: none;
    }
   }
   .busineess-rightSidebar {
    overflow: hidden;
    @media(max-width:768px) {
        order: 4;
     }
    .business-wrap {
        // width: 400px;
        // height: 400px;
        text-align: right;
        margin-left: auto;
        width: 498px;
        height: 498px;
        @media(max-width:768px) {
            height: 249px;
        width: 249px;
        margin-left: 0;
         }
        img {
            object-fit: cover;
            height: 100%;
        width: 100%;
    border-radius: 50%;    
    @media(max-width:768px) {
        border-radius: 50%;    
    }
    }
    }
    .mr {
        margin-right: auto;
         }
   }
}

    .margin-72 {
        margin-top: 72px;
    } 

    .padding-80 {
        padding-top: 80px;
        @media(max-width:768px) {
            padding-top: 40px;
        }
    }

    .fistSectionPadding {
        @media(max-width:768px) {
            padding-top: 40px;
        }
    }