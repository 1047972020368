@import '../../../../Assets/Common/base.scss';
.file-wrapper {
.file-container {
    
    width: 291px;
    border: 1px solid rgba($base, 0.2);
    border-radius: 4px;
    cursor: pointer;
    input[type="file"] {
        padding: 10px 18px;
        background-color: $gray;
        cursor: pointer;
    }
    
    input[type="file"]::file-selector-button {
        border: 2px solid rgba($base, 0.1);
        padding: 8px 20px;
        border-radius: 0.2em;
        background-color: $white;
        transition: 1s;
        color: $base;
        font-weight: 400;
        cursor: pointer;
      }
    
    }
    .accept-formet {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-top: 8px;
      }
}