@import '../../../Assets/Common/base.scss';

.contactsection-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 140px;
    @media(max-width:768px) {
        grid-template-columns: 1fr;
        gap: 40px;
    }
    .contactSection-leftSidebar {
        .contact-p {
            font-weight: 600;
            line-height: 30px;
        }

        .contact-info-wrap {
            .contact-info-heading {
                font-size: 56px;
                font-weight: 500;
                line-height: 72px;
            }
            .team-para {
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
            }

            .address-wrap {
                font-family: 'Poppins', sans-serif;
             margin-top: 36px;
                .icon-wrap {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 46px;
                    margin-bottom: 24px;
                    p {
                        font-size: 14px;
                        font-weight: 400;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .icon-call {
        display: block;
        width: 15px;
        height: 15px;
        background-color: $base;
        -webkit-mask: url("../../../Assets/icons/call.svg") no-repeat center;
        mask-image: url("../../../Assets/icons/call.svg") no-repeat center;
      }
      
    .icon-mail {
        display: block;
        width: 15px;
        height: 15px;
        background-color: $base;
        -webkit-mask: url("../../../Assets/icons/mail.svg") no-repeat center;
        mask-image: url("../../../Assets/icons/mail.svg") no-repeat center;
      }
      
    .icon-loaction {
        display: block;
        width: 15px;
        height: 15px;
        background-color: $base;
        -webkit-mask: url("../../../Assets/icons/location.svg") no-repeat center;
        mask-image: url("../../../Assets/icons/location.svg") no-repeat center;
      }
}