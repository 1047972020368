@import '../../../Assets/Common/base.scss';
.ourbusiness-container {
    .top-heading-wrap {
        width: 68%;
        margin: auto;

        @media(max-width:768px) {
           width: 100%;
         } 
        .business-top-para {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            color: rgba($base, 0.6);
            text-align: center;
            margin-top: 24px;
        }

        .business-btn-wrap {
            margin-top: 60px;
        }
    }
    .tab-container {
        display: flex;
        justify-content:space-evenly;
        padding-top: 84px;

        @media(max-width:768px) {
            margin-top: 60px;
         } 
        @media(max-width:500px) {
           flex-direction: column;
           gap: 30px;
        } 
        .btn-tab {
            border: none;
            outline: none;
            font-size: 40px;
            line-height: 50px;
            font-weight: 700;
            color: $base;
            padding: 12px 32px !important;
            height: 74px;
text-transform: capitalize;
border-radius: 58px!important;
transition-duration: 700ms;
            @media(max-width:768px) {
                height: 30px;
                padding: 8px 18px !important;
                font-size: 32px;
            }
            @media(max-width:600px) {
                height: 30px;
                padding: 8px 18px !important;
                font-size: 18px;
            }
            &:hover{
                background: linear-gradient(90deg, $secondary -22.25%, $primary 113.25%);
                border: transparent;
                color: $white;
            }
        }

          .tab-active {
            border-radius: 30px;
            padding: 12px;
            background: linear-gradient(90deg, $secondary -22.25%, $primary 113.25%);
            border: transparent;
            color: $white;
          
            &:hover {
              background-position: left center;
            } 
    }
 

    }
    .tab-content-container {
        overflow: hidden;
        .show {
            display: block;
        }
        
        .displayHide {
            display: none;
        }
    }

}