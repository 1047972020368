@import '../../../../Assets/Common/base.scss';

.innovation-wrap {
    display: flex;
    margin-top: 124px;
    gap: 60px;
    align-items: flex-start;
    width: 100%;
    position: relative;
    @media(max-width:768px) {
        flex-direction: column;
        gap: 60px;
        margin-top: 60px;
    }
    .invoation-leftSidebar {
        width: 50%;
        @media(max-width:768px) {
            width: 100%;
            order: 6;
        }
        .invoation-heading {
            font-size: 36px;
            font-weight: 600;
            line-height: 43px;
        }
        .invoation-para {
            font-size: 20px;
            font-weight: 400;
             line-height: 36px;
             margin-top: 24px;
        }

        .edneed-btn-wrap {
            margin-top: 24px;
        }
    }
    .invoation-rightSidebar {
        @media(max-width:768px) {
          order: 5;
          margin-top: 32px;
        }
        img {
            max-width: 100%;
            position: absolute;
            right: 0;
            top: 0;
            @media(max-width:768px) {
               position: relative;
              }
        }
    }

}

.our-feature-wrap {
    margin-top: 96px;
    .feature-p {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
    }

    .feature-content-wrap {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        margin-top: 40px;

        @media(max-width:768px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        @media(max-width:475px) {
            display: grid;
            grid-template-columns: 1fr ;
            gap: 36px;
        }


        .feature-card-container {
             position: relative;
                width: 33.33%;
                padding: 20px;
                @media(max-width:768px) {
                    width: 100%;
                }
    
               .backgroud-imag {
                  position: absolute;
                  top: -10px;
                  left: -20px;
                  z-index: -10;
                  img {
                    max-width: 100%;
                  }
              }
             .feature-content {
                .feature-card-header {
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 38px;
                }
                .feature-content-p {
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 28px;
                  margin-top: 16px;
                } 
             }
        }
    }
}
.partner-container {
    margin-top: 112px;
    display: grid;
    gap: 60px;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: flex-end;
    
    @media(max-width:768px) {
        grid-template-columns: 1fr;
        margin-top: 60px;
    }

    .partner-leftSidebar {
        .brand-p {
            font-size: 16px;
            font-weight: 600;
            line-height: 88px;
        }

        .client-heading {
            font-size: 64px;
            font-weight: 500;
            line-height: 88px;
        }
        .client-subheading {
            font-size: 14px;
            width: 400;
            line-height: 16px;
        }

    }
    .partner-rightSidebar {
        .parner-images-container {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            .image-div {
                img {
                    max-width: 100%;
                }
            }
        }
    }
}

.btn-parnet-wrap {
    margin-top: 36px;
}