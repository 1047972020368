@import '../../../Assets/Common/base.scss';

.pagination {
    display: flex;
    align-items: center;
    gap: 20px;
    list-style-type: none;
    position: relative;

    @media(max-width:425px) {
      gap: 10px;
    }

    li {
        color: black;
        float: left;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          font-size: 14px;
          font-weight: 600;
          color: $base;
        }
        .dots {
          font-size: 20px;
        }
      }
      .active {
        background: 
        linear-gradient($white,$white) padding-box,
        linear-gradient(270deg, $primary , $secondary ) border-box;
        border: 1px solid transparent;
        color: white;
      }
  }
  

  

  
  // .pagination li:hover:not(.active) {
  //   background-color: #ddd;
  //   border-radius: 5px;
  // }