@import '../../../Assets/Common/base.scss';

.aboutUs-container {
padding: 72px 0;
    .aboutTopSection {
        position: relative;
        .aboutTopSectionGrid{
          display: grid;
          grid-template-columns: 1fr 1fr ;
          align-items: flex-end;
          gap:48px;
          margin-top: 72px;
          @media(max-width:992px) {
            grid-template-columns: 1fr  ;
          }
          .aboutTop-leftside {
            img {
                max-width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                @media(max-width:768px) {
                    position: relative;
                  }
            }
          }
        }
    }
    .aboutTop-rightside {
        width: 100%;
        .who-p {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.08em;
            line-height: 30px;
        }
        .who-subheading {
            font-size: 36px;
            line-height: 43px;
        }
        .aboutus-para {
            font-weight: 500;
            color: rgba($base, 0.6);
            line-height: 32px;
            margin-top: 24px;
        }
    }

    .teamSection {
        margin-top: 72px;
        .teamHeading-para {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            color: rgba(32, 32, 32, 0.4);
            width: 65%;
            margin: auto;
            text-align: center;
            margin-top: 16px;
            @media(max-width:768px) {
                width: 100%;
            }
        }

        .team-images-section {
            display: flex;

            // grid-template-columns: repeat(4,1fr);
            column-gap:96px;
            row-gap: 72px;
            margin-top: 72px;
            flex-wrap: wrap;
            justify-content: center;
            .team-wrap {
                @media(max-width:768px) {
                    width: 100%;
                    height: 100%;
                }
            .image-wrap{
                width: 276px;
                height: 327px;
                border-radius: 327px;
               
                @media(max-width:768px) {
                    width: 100%;
                    height: 100%;
                }
               
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 327px;
                }
            }
            .team-name {
                margin-top: 24px;
                font-size: 24px;
                font-weight: 600px;
                line-height: 28px;
                @media(max-width:768px) {
                    font-size: 18px;
                    font-weight: 600px;
                    line-height: 22px;
                }
            }

            .team-position {
                font-size: 14px;
                font-weight: 600px;
                color: #A6A6A6;
            }
        }
        }
    }
    .ourValuesSection {
        margin-top: 72px;
        position: relative;
        .value-container {
            .value-container-grid{
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: space-between;
            align-items: flex-end;
             gap: 130px;
             margin-top: 72px;
             @media(max-width:992px) {
                grid-template-columns: 1fr  ;
                gap: 40px;
              }
            .value-leftSidebar {
                @media(max-width:992px) {
                 order: 3;
                  }
              .intri-wrap {
                .intri-heading {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 28px;
                    color: $primary;
                }
                .intri-para {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 32px;
                    color: rgba($base, 0.6);
                }
              }
              .value-gap {
                margin-top: 48px;
              }
            }
            .value-rightSidebar {
                @media(max-width:992px) {
                    order: 2;
                     }
                img {
                    max-width: 100%;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    @media(max-width:768px) {
                       position: relative;
                         }
                }
            }
        }
        }
    }
}