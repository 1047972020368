@import '../../../Assets/Common/base.scss';

.rivew-wrapper {
  .customer-heading {
    margin-bottom: 60px;
  }

  .slick-slider {

    .slick-slide {
      margin: 0 0;
      padding: 16px;
    }

    .slick-list {
      margin: 0 0;
    }

    .slick-next {
      left: auto;
      right: 0;
      background-color: #FFFFFF;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 8px 20px 2px rgba(0, 0, 0, 0.08);
      z-index: 1;

      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-left: 2px solid #AEAEAE;
        border-bottom: 2px solid #AEAEAE;
        top: 7px;
        left: 6px;
        transform: rotate(-140deg);

      }
    }

    .slick-prev {
      left: auto;
      background-color: #FFFFFF;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 8px 20px 2px rgba(0, 0, 0, 0.08);
      z-index: 1;


      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-left: 2px solid #AEAEAE;
        border-bottom: 2px solid #AEAEAE;
        top: 8px;
        left: 8px;
        transform: rotate(45deg);
      }
    }

    .image-wrap {
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      img {
        width: 118px;
        height: 118px;
        object-fit: cover;
        // position: absolute;
        z-index: 1;
        border-radius: 50%;
      }

      .intro-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #FFF;
        border:  1px solid #F3F3F3;
        // box-shadow: 0px 8px 20px 2px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        padding: 72px 24px 24px 24px;
        align-items: center;
        margin-top: -59px;
        z-index: 0;
        min-height: 300px;

        .name {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          color: #202020;
        }

        .designation {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;

          color: rgba(32, 32, 32, 0.4);
        }

        .details {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: rgba(32, 32, 32, 0.6);
          margin-top: 32px;
        }

        .starSection {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          margin-top: 8px;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .icon-star {
    display: block;
    width: 16px;
    height: 16px;
    background-color: #FFD467;
    -webkit-mask: url("../../../Assets/icons/star.svg") no-repeat center;
    mask-image: url("../../../Assets/icons/star.svg") no-repeat center;
  }
}