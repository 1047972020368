@import './theme';

.button {
  font-family: 'Syne', sans-serif;

    padding: 8px 20px;
    word-break: keep-all;
    outline: none;
    font-weight: 500;
    height: 40px;
    font-size: 12px;
    border-radius: 4px;
    box-shadow: 0px, 0px, 1px rgba(0, 0, 0, 0.15);
    word-spacing: 0.1em;
    display: inline-flex;
    column-gap: 4px;
    align-items: center;
    position: relative;
    justify-content: space-around;
    text-transform: uppercase;
    color: $primary;
    cursor: pointer;
    background-color: $white;
    
    transition-duration: 700;
    @media(min-width:1200px) {
      padding: 10px 35px;
    }
  
    @media(max-width:992px) {
      font-size: 13px;
    }
  }
.btn-sm {
  padding: 4px 22px;
  height: 32px;
}
  .btn-oval {
    border-radius: 30px;
  }

  .btn-block {
    width: 100%;
  }

  .btn-primary {
    background: linear-gradient(90deg, $secondary -22.25%, $primary 113.25%);
    border: transparent;
    color: $white;
    height: 45px;
    &:hover {
      background-position: left center;
    }
  }

  .btn-o-primary {
    background: 
    linear-gradient($white,$white) padding-box,
    linear-gradient(270deg, $primary , $secondary ) border-box;
    border: 1px solid transparent;
    color: $base;
    font-weight: 500;
&:hover{
  background: linear-gradient(90deg, $secondary -22.25%, $primary 113.25%);
color: #FFF;

}
  }

  .btn-blue {
    background-color: $primary;
    color: $white;
    border: 1px solid $primary;
  }