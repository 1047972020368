

* {
    margin: 0;
    padding: 0;
}




// 
// ::selection {
//     background: $primary ;
//     color: #fff;
  
//   }
  
//   ::-moz-selection {
//     background: $primary  ;
//     color: #fff;
//   }
  
  
  * {
    scrollbar-width: thin;
    scrollbar-color: $primary;
  }
  
  *::-webkit-scrollbar {
    height: 10px;
    /* height of horizontal scrollbar ← You're missing this */
    width: 10px;
    /* width of vertical scrollbar */
  }
  
  *::-webkit-scrollbar-track {
    background: #ececec !important;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: $primary  !important;
    border-radius: 0;
    border: 0 solid #ececec;
  }
  