@import '../../../Assets/Common/base.scss';

.joinTeam-container {
    display: flex;
    // align-items: flex-start;
    // height: 100%;
    @media(max-width:768px) {
        flex-direction: column;
    }

    .joinTeam-wrapper {
        width: 50%;
        @media(max-width:768px) {
            width: 100%;
        }
        background: linear-gradient(90deg, #D5CCEA -34.77%, #9ED1ED 139.6%);
      padding: 105px 80px;
      @media(max-width:768px) {
        padding: 70px 20px;
      }
      .team-content-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start ;

        .content-leftSidebar {
            .team-heading {
                font-size: 48px;
                font-weight: 600;
                line-height: 57px;
                margin-bottom: 16px;
            }
        }

        .content-rightSidebar {
            margin-top: -20px;
            .circle {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                border: 1px solid $secondary;
                position: relative;
                cursor: pointer;
                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }

            }
        }
      }
      
    }

    .subscribe-wrapper {
        width: 50%;
        @media(max-width:768px) {
            width: 100%;
        }
        background-color: $gray !important;
        padding: 105px 80px;
        @media(max-width:768px) {
            padding: 70px 20px;
          }
        .team-content-wrap {
            // display: flex;
            // align-items: center;
            // justify-content: flex-start ;
    
            .content-leftSidebar {
                .team-heading {
                    font-size: 48px;
                    font-weight: 600;
                    line-height: 57px;
                    margin-bottom: 16px;
                }
            }
                .subscribe-input-wrap {
                    // display: flex;
                    // align-items: flex-start;
                    // justify-content: flex-start;
                    display: grid;
    grid-template-columns: 1fr auto;
                    gap: 12px;
                    margin-top: 24px;
                    .input_error{
                        font-size: 14px;
                    color: #e71111;
                    }
                    .group{
                        margin-top: 0;
                    }
                    .form-input {
                        width: 100%;
                        height: 40px;
                        border: none;
                        outline: none;
                        background-color: $white;
                        padding: 8px 16px;

                    }
            }
          }
    }


    .icon-right {
        display: block;
        width: 10px;
        height: 10px;
        background-color: $base;
        -webkit-mask: url("../../../Assets/icons/arrow.svg") no-repeat center;
        mask-image: url("../../../Assets/icons/arrow.svg") no-repeat center;
      }
}