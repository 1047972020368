@import '../../Assets/Common/base.scss';
.apply-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($base, 0.6);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    .apply-wrapper {
        width: 90%;
        max-width: 800px;
        height: 600px;
        overflow-y: auto;
        background-color: $white;
        padding: 24px;
        border-radius: 8px;
    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-wrapper {
            .apply-heading {
                font-size: 24px;
                font-weight: 600;
                line-height: 28px;
            }
           
        }
    }

    .apply-body {
        margin-top: 24px;
        .apply-role-heading {
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
        }
        .apply-role-para {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: rgba($base, 0.6);
            margin-top: 6px;
        }
        .form-btn-wrap {
            margin-top: 36px;
        }
        .group{
            .form-input{
                margin: 0;
            }
        }
        .input_error{
            font-size: 14px;
            color: #e71111;
        }

    }
    .jobApply-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .cong-para {
            font-size: 24px;
            font-weight: 500;
            color:#00AD41;
            line-height: 28px;
        }
        .cong-position {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            color: #000; 
            margin-top: 36px;
        }
        .cong-keep {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: rgba($base, 0.6);
            margin-top: 8px;
        }
    }
    .icon-cross {
        display: block;
        width: 12px;
        height: 12px;
        background-color: $base;
        -webkit-mask: url("../../Assets/icons/cross.svg") no-repeat center;
        mask-image: url("../../Assets/icons/cross.svg") no-repeat center;
        cursor: pointer;
      }

    }
}