
.hero-heading {
    font-size: 56px;
    font-weight: 600;
    line-height: 72px;
    background: linear-gradient(90.36deg, $secondary -10.4%, $primary 110%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

@media(max-width:768px) {
    font-size: 32px;
    line-height: 42px;
    }

}

.section-topgap {
    margin-top: 120px;
    @media(max-width:768px) {
        margin-top: 60px;
    }
}
.pageBottomgap {
    padding-bottom: 120px;
    @media(max-width:768px) {
        padding-bottom: 80px;
    }
}

.padding-top {
    padding-top: 96px;
    @media(max-width:768px) {
        padding-top: 40px;
    }
}

.main-heading {
    font-size: 64px;
    font-weight: 600;
    line-height: 77px;
    background: linear-gradient(90deg, #0184FF -22.62%, #B295F1 75.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center;
    @media(max-width:768px) {
        font-size: 32px;
    line-height: 42px;
        }
}

.text-center {
    text-align: center;
}

p {
    font-size: 16px;
    font-weight: 500;
    color: $base;
    line-height: 30px;
}
