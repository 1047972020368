.faq-wrapper {
    padding: 120px 0;
    width: 80%;
    margin: auto;

    .faq-head {
        display: flex;
        flex-direction: column;
        gap: 24px;

        h2 {
            font-weight: 600;
            font-size: 64px;
            line-height: 77px;
            text-align: center;
            background: linear-gradient(90deg, #0184FF -22.62%, #B295F1 124.29%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #202020;
        }
    }

    .faq-content {
        margin-top: 72px;

        article {
            display: flex;
            flex-direction: column;
            border-top: 1px solid #EEEEEE;

            &.active {
                .faqHead {
                    color: #2488FD;

                    .caret {
                        border-color: #2488FD;
                    }
                }
            }

            .faqHead {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #202020;
                padding: 18px 0 18px 18px;
                cursor: pointer;
                position: relative;
                // margin: 5px 0;
                background-color: #FFF;
                position: relative;
                transition-duration: 500ms;

                @media screen and (max-width: 600px) {
                    padding: 18px 40px 18px 25px;
                }

                .caret {
                    position: absolute;
                    right: 15px;
                    top: 22px;
                    width: 8px;
                    height: 8px;
                    border-right: 1px solid #202020;
                    border-bottom: 1px solid #202020;
                    transform: rotate(45deg);
                    transition-duration: 600ms;

                    &.active {
                        transform: rotate(-135deg);
                        top: 28px;
                    }
                }

                &:hover {
                    color: #2488FD;

                    .caret {
                        border-color: #2488FD;
                    }
                }
            }

            .faqAnswerDisp {
                display: block;
                font-size: 14px;
                list-style: 17px;
                color: rgba(32, 32, 32, 0.6);
                padding: 0 24px 24px 24px;
                line-height: 1.55;
                max-height: 150px;
                overflow: hidden;
                overflow-y: auto;

                ol {
                    padding-left: 15px;

                    li {
                        margin: 5px 0;
                    }
                }
            }
        }
    }
}